// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Name from '../ff/name'
import Email from '../ff/email'

import debounceForm from '../../methods/debounce-form'
import makeQueryString from '../../methods/make-query-string'

import { name, email } from '../../methods/default-schema'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const macroId =
  'AKfycbzthxkY3rfxRB4JCw_xIT5C0cDTZZY-iWuZs_AuW97iUqgSWbV-RRFldytx0tUv9W3z'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const NewsletterForm = (props) => {
  const [state, setState] = useState({
    serverMessage: 'Not yet initialized.',
    started: false,
    finished: false,
  })

  const form = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: Yup.object().shape({
      name,
      email,
    }),
    onSubmit: (values) => {
      debounceForm(setState, () => {
        const queryString = makeQueryString(values)

        fetch(
          `https://script.google.com/macros/s/${macroId}/exec?${queryString}callback=?`,
          {
            method: 'GET',
            mode: 'no-cors',
          }
        )
          .then((response) => {
            setState({
              serverMessage: "We've added you to our list.",
            })
          })
          .catch((error) => {
            setState({ serverMessage: 'Error...' })
          })
      })
    },
  })

  const { serverMessage, started } = state

  return (
    <Fragment>
      <form
        onSubmit={form.handleSubmit}
        className="as-paragraph newsletter-form"
      >
        <Name form={form} />
        <Email form={form} />
        <button type="submit">Subscribe</button>
      </form>
      {started === true && <p>{serverMessage}</p>}
    </Fragment>
  )
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default NewsletterForm
