// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const IGSvgSymbol = (props) => {
  return (
    <svg
      width={900}
      height={900}
      viewBox="0 0 900 900"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h900v900H0z" />
        <path
          d="M736.655 20h-573.31A143.327 143.327 0 0061.994 61.992a143.34 143.34 0 00-41.992 101.351v573.31a143.327 143.327 0 0041.992 101.351 143.34 143.34 0 00101.35 41.993h573.311a143.327 143.327 0 00101.351-41.993 143.34 143.34 0 0041.992-101.35v-573.31a143.327 143.327 0 00-41.992-101.352A143.34 143.34 0 00736.656 20zm71.655 716.655h.034a71.706 71.706 0 01-20.996 50.693 71.705 71.705 0 01-50.693 20.996h-573.31c-39.607 0-71.689-32.082-71.689-71.689v-573.31c0-39.608 32.082-71.689 71.689-71.689h573.31a71.706 71.706 0 0150.693 20.996 71.705 71.705 0 0120.996 50.693l-.034 573.31z"
          fill="#000"
          fillRule="nonzero"
        />
        <path
          d="M450 235a214.89 214.89 0 00-152.014 62.988 214.882 214.882 0 00-62.988 152.014 214.89 214.89 0 0062.988 152.013A214.882 214.882 0 00450 665.003a214.89 214.89 0 00152.014-62.988 214.882 214.882 0 0062.988-152.013 214.89 214.89 0 00-62.988-152.014A214.882 214.882 0 00450 235zm0 358.345a143.327 143.327 0 01-101.351-41.992 143.34 143.34 0 01-41.992-101.351 143.33 143.33 0 0141.992-101.351A143.34 143.34 0 01450 306.659a143.33 143.33 0 01101.351 41.992A143.34 143.34 0 01593.343 450a143.33 143.33 0 01-41.992 101.352A143.34 143.34 0 01450 593.345zM736.655 217.095c0 29.663-24.053 53.75-53.75 53.75-29.663 0-53.75-24.087-53.75-53.75 0-29.697 24.087-53.75 53.75-53.75 29.697 0 53.75 24.053 53.75 53.75"
          fill="#000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default IGSvgSymbol
