// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import getRandomArbitraryInt from './getRandomArbitraryInt'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Function
// ----------------------------------------------------------------------------
/** debounceForm */
const debounceForm = (setState, fx) => {
  setState({ serverMessage: 'Working...', started: true })
  setTimeout(() => {
    setState({ serverMessage: 'Sending data...' })
    setTimeout(() => {
      setState({ serverMessage: 'Waiting...' })
      setTimeout(() => {
        setState({ serverMessage: 'Recieved response...', finished: true })
        setTimeout(() => {
          fx()
        }, getRandomArbitraryInt(500, 800))
      }, getRandomArbitraryInt(500, 800))
    }, getRandomArbitraryInt(500, 800))
  }, getRandomArbitraryInt(500, 800))
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default debounceForm
