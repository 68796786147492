// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import sample from 'lodash/sample'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { StaticQuery, graphql } from 'gatsby'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import NewsletterForm from '../newsletter-form'
import '../newsletter-form/style.less'

import Google from '../svg/google'
import Apple from '../svg/apple'
import IG from '../svg/ig'
import Spotify from '../svg/spotify'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

export const query = graphql`
  query {
    img1: file(relativePath: { eq: "aside/img1.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Footer */
const Footer = () => (
  <footer>
    <Row
      gutter={[
        { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
      ]}
    >
      <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
        <aside style={{ display: 'flex' }}>
          <Row
            gutter={[
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
            ]}
          >
            <Col xs={0} sm={8} md={12} lg={12} xl={12} xxl={12}>
              <StaticQuery
                query={query}
                render={(data) => (
                  <GatsbyImage
                    objectFit="contain"
                    image={getImage(data.img1)}
                  />
                )}
              />
            </Col>
            <Col xs={24} sm={16} md={12} lg={12} xl={12} xxl={12}>
              <h2>Join the Network!</h2>
              <p className="hero">
                The Aura Network strives for a cashless community, a local
                economy that empowers individuals for a life of freedom and joy.
                As members of the community discover the abundance in their
                lives they naturally share with each other what they value.
              </p>
              <Link
                className="unset"
                to="https://play.google.com/store/apps/details?id=app.auranetwork"
                style={{ display: 'inline-block', marginBottom: '0.555rem' }}
              >
                <Google style={{ height: '3rem', width: '9.99rem' }} />
              </Link>
              <br />
              <Link
                className="unset"
                to="https://apps.apple.com/app/aura-network/id1525877177?l=en"
                style={{ display: 'inline-block', marginBottom: 0 }}
              >
                <Apple style={{ height: '3rem', width: '9.99rem' }} />
              </Link>
            </Col>
          </Row>
        </aside>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <aside>
          <h2>Newsletter</h2>
          <p className="hero">
            Signup for our newsletter by filling in this form to receive
            somewhat regular updates from us.
          </p>
          <NewsletterForm />
        </aside>
      </Col>
    </Row>
    <p className="quote">
      <big>
        <q>
          The purpose of Aura Network is to plant a seed of love, prosperity and
          unity in this fertile soil of Auroville for the world.
        </q>
      </big>
    </p>
    <p className="social">
      <Link
        to="https://www.instagram.com/aura.auroville/?hl=en"
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <IG style={{ height: '1.66rem', width: '1.66rem' }} />
        <span>&nbsp;&nbsp;Find us on Instagram</span>
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link
        to="https://open.spotify.com/show/70d1MuVgxgiH9OBMeDYc2x"
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <Spotify style={{ height: '1.66rem', width: '1.66rem' }} />
        <span>&nbsp;&nbsp;Find us on Spotify</span>
      </Link>
    </p>
    <p className="copyright">
      Copyright © 2021 <Link to="/">Aura Network</Link>
      <br />
      Website handcrafted by{' '}
      <Link to="https://design.prisma.haus" style={{ whiteSpace: 'nowrap' }}>
        Prisma Design
      </Link>
    </p>
  </footer>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Footer
