// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
import Helmet from 'react-helmet'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import website from '../../seo/website.json'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** GeneralMetaData */
const GeneralMetaData = ({
  data: { slug, description, keywords, image } = {},
}) => (
  <Helmet
    meta={[
      // ---- General SEO
      { name: 'description', content: description },
      { name: 'keywords', content: keywords },
      { name: 'image', content: image },
      { name: 'robots', content: 'index,follow' },
    ]}
    link={[{ rel: 'canonical', href: `${website.nakedUrlWithIntl}${slug}` }]}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default GeneralMetaData
