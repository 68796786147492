// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useEffect } from 'react'
import classnames from 'classnames'

import isNumber from 'lodash/isNumber'
import min from 'lodash/min'
import max from 'lodash/max'

import { scaleLinear } from 'd3-scale'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { useMediaQuery } from 'react-responsive'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const steps = [
  200,
  400,
  600,
  800,
  1000,
  1200,
  1400,
  1600,
  1800,
  2000,
  2200,
  2400,
  2600,
]

const range = [15, 25]

const scale = scaleLinear()
  .domain([min(steps), max(steps)])
  .range([min(range), max(range)])
  .clamp(true)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Methods
// ----------------------------------------------------------------------------
/** [description] */
const closestIndex = (arr, target) => {
  var i = 0,
    j = arr.length - 1,
    k

  while (i <= j) {
    k = Math.floor((i + j) / 2)
    if (target === arr[k] || Math.abs(i - j) <= 1) {
      return k
    } else if (target < arr[k]) {
      j = k - 1
    } else {
      i = k + 1
    }
  }
  return -1
}

/** [description] */
const getClosest = (arr, target) => {
  const closest = closestIndex(arr, target)
  const result = [arr[closest]]

  if (isNumber(arr[closest - 1]) && isNumber(arr[closest + 1])) {
    if (
      Math.abs(target - arr[closest - 1]) < Math.abs(target - arr[closest + 1])
    ) {
      result.push(arr[closest - 1])
    } else {
      result.push(arr[closest + 1])
    }
  }

  return result
}

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const MediaState = ({
  children,
  className: givenClassName = '',
  mediaState,
  updateMediaState,
  client,
  screenSizeState = undefined,
  updateScreenSizeState = undefined,
  ...otherProps
}) => {
  const fx = useMediaQuery

  // Defaults
  let screenWidth = 1440
  let screenHeight = 900

  // Defs
  const isExtraExtraLargeDesktop = fx({ minWidth: 1600 })
  const isExtraLargeDesktop = fx({ minWidth: 1201, maxWidth: 1599 })
  const isLargeDesktop = fx({ minWidth: 993, maxWidth: 1200 })
  const isDesktop = fx({ minWidth: 769, maxWidth: 992 })
  const isTablet = fx({ minWidth: 577, maxWidth: 768 })
  const isMobile = fx({ maxWidth: 576 })
  const isAsHighAsMyImac = fx({ minHeight: 1440 })
  const isTwoThirdsAsHighAsMyImac = fx({ minHeight: 950, maxHeight: 1439 })
  const isHalfAsHighAsMyImac = fx({ minHeight: 720, maxHeight: 949 })
  const isOneThirdAsHighAsMyImac = fx({ minHeight: 480, maxHeight: 719 })
  const isOneFourthAsHighAsMyImac = fx({ minHeight: 360, maxHeight: 479 })
  const isVeryShortWhenComparedToMyImac = fx({ maxHeight: 359 })
  const isPortrait = fx({ orientation: 'portrait' })
  const isRetina = fx({ minResolution: '2dppx' })

  // Get point
  const neighbours = getClosest(steps, screenWidth)
  const smallerNeighbour = max(neighbours)
  let point = scale(smallerNeighbour)

  // Classname
  const className = classnames(
    givenClassName,
    {
      'is-media-aware': true,
      'is-extra-extra-large-desktop': isExtraExtraLargeDesktop,
      'is-extra-large-desktop': isExtraLargeDesktop,
      'is-large-desktop': isLargeDesktop,
      'is-desktop': isDesktop,
      'is-tablet': isTablet,
      'is-mobile': isMobile,
      'is-as-high-as-my-imac': isAsHighAsMyImac,
      'is-two-thirds-as-high-as-my-imac': isTwoThirdsAsHighAsMyImac,
      'is-half-as-high-as-my-imac': isHalfAsHighAsMyImac,
      'is-one-third-as-high-as-my-imac': isOneThirdAsHighAsMyImac,
      'is-one-fourth-as-high-as-my-imac': isOneFourthAsHighAsMyImac,
      'is-very-short-when-compared-to-my-imac': isVeryShortWhenComparedToMyImac,
      'is-portrait': isPortrait,
      'is-retina': isRetina,
      'is-client': client,
    },
    `point-${point}`
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      ;({ innerWidth: screenWidth, innerHeight: screenHeight } = window)
    }

    // Get point
    const neighbours = getClosest(steps, screenWidth)
    const smallerNeighbour = max(neighbours)
    let newPoint = scale(smallerNeighbour)

    const newMediaState = {
      isExtraExtraLargeDesktop,
      isExtraLargeDesktop,
      isLargeDesktop,
      isDesktop,
      isTablet,
      isMobile,
      isAsHighAsMyImac,
      isHalfAsHighAsMyImac,
      isOneFourthAsHighAsMyImac,
      isVeryShortWhenComparedToMyImac,
      isPortrait,
      isRetina,
      currentWidth:
        isExtraExtraLargeDesktop === true
          ? 'isExtraExtraLargeDesktop'
          : isExtraLargeDesktop === true
          ? 'isExtraLargeDesktop'
          : isLargeDesktop === true
          ? 'isLargeDesktop'
          : isDesktop === true
          ? 'isDesktop'
          : isTablet === true
          ? 'isTablet'
          : 'isMobile',
      currentHeight:
        isAsHighAsMyImac === true
          ? 'isAsHighAsMyImac'
          : isTwoThirdsAsHighAsMyImac === true
          ? 'isTwoThirdsAsHighAsMyImac'
          : isHalfAsHighAsMyImac === true
          ? 'isHalfAsHighAsMyImac'
          : isOneThirdAsHighAsMyImac === true
          ? 'isOneThirdAsHighAsMyImac'
          : isOneFourthAsHighAsMyImac === true
          ? 'isOneFourthAsHighAsMyImac'
          : 'isVeryShortWhenComparedToMyImac',
      screenWidth,
      screenHeight,
      point: newPoint,
    }
    updateMediaState({ ...mediaState, ...newMediaState })
  }, [
    isExtraExtraLargeDesktop,
    isExtraLargeDesktop,
    isLargeDesktop,
    isDesktop,
    isTablet,
    isMobile,
    isAsHighAsMyImac,
    isTwoThirdsAsHighAsMyImac,
    isHalfAsHighAsMyImac,
    isOneThirdAsHighAsMyImac,
    isOneFourthAsHighAsMyImac,
    isVeryShortWhenComparedToMyImac,
    isPortrait,
    isRetina,
    screenWidth,
    screenHeight,
  ])

  return (
    <div className={className} {...otherProps}>
      {children}
    </div>
  )
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default MediaState
