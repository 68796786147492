// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
import Helmet from 'react-helmet'

import website from '../../seo/website.json'
import organisation from '../../seo/organisation.json'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
  * TwitterSummaryCardWithLargeImage - Implements the Large Twitter Summary Card
  *
  * The Summary Card with Large Image features a large, full-width prominent 
  * image alongside a tweet. It is designed to give the reader a rich photo 
  * experience, and clicking on the image brings the user to your website.
  *
  * @example
    <TwitterSummaryCardWithLargeImage data={data}>
  *
  * @param {string} data.site - Optional. The Twitter @username for the organisation that owns the website.
  * @param {string} data.creator - Optional. The Twitter @username for the content creator / author.
  * @param {string} data.title - Required. A concise title for the related content.
  * @param {string} data.description - Required. A description that concisely summarizes the content as appropriate for presentation within a Tweet. You should not re-use the title as the description or use this field to describe the general services provided by the website.
  * @param {string} data.image - Required. A URL to a unique image representing the content of the page. You should not use a generic image such as your website logo, author photo, or other image that spans multiple pages. Images for this Card support an aspect ratio of 2:1 with minimum dimensions of 300x157 or maximum of 4096x4096 pixels. Images must be less than 5MB in size. JPG, PNG, WEBP and GIF formats are supported. Only the first frame of an animated GIF will be used. SVG is not supported.
  * @param {string} data.imageAlt - Optional. A text description of the image conveying the essential nature of an image to users who are visually impaired. Maximum 420 characters.
  *
  * @version 0.0.1
  * @todo none
  * @see {@link https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary-card-with-large-image|Twitter Docs}
  */
const TwitterSummaryCardWithLargeImage = ({
  data: { image, description, title } = {},
}) => (
  <Helmet
    meta={[
      // ---- Twitter Card tags
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: website.name },
      { name: 'twitter:creator', content: organisation.name },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:image', content: image },
    ]}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default TwitterSummaryCardWithLargeImage
