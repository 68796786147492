// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const SpotifySvgSymbol = (props) => {
  return (
    <svg
      width={900}
      height={900}
      viewBox="0 0 900 900"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h900v900H0z" />
        <path
          d="M449.979 11.451c-242.257 0-438.654 196.392-438.654 438.649 0 242.267 196.397 438.643 438.654 438.643 242.283 0 438.66-196.376 438.66-438.643 0-242.241-196.377-438.628-438.665-438.628l.005-.021zm201.164 632.657c-7.857 12.886-24.724 16.972-37.61 9.062-102.991-62.91-232.644-77.157-385.335-42.271-14.714 3.352-29.38-5.867-32.733-20.586-3.368-14.72 5.815-29.386 20.565-32.738 167.095-38.191 310.425-21.738 426.051 48.924 12.886 7.91 16.971 24.723 9.062 37.61zm53.69-119.455c-9.9 16.107-30.957 21.188-47.038 11.288-117.91-72.49-297.644-93.479-437.108-51.144-18.087 5.463-37.19-4.73-42.68-22.786-5.448-18.087 4.75-37.154 22.806-42.654 159.307-48.337 357.354-24.923 492.758 58.284 16.081 9.9 21.162 30.958 11.262 47.018v-.006zm4.61-124.373c-141.376-83.972-374.629-91.693-509.61-50.726-21.674 6.574-44.596-5.662-51.165-27.337-6.568-21.686 5.657-44.592 27.348-51.182 154.948-47.038 412.532-37.95 575.3 58.677 19.538 11.571 25.929 36.75 14.353 56.22-11.524 19.497-36.772 25.924-56.205 14.348h-.021z"
          fill="#000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default SpotifySvgSymbol
