// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import { connect } from 'react-redux'
import compose from 'recompose/compose'
import pure from 'recompose/pure'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import DesktopNav from './desktop-nav'
import MobileNav from './mobile-nav'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// export const query = graphql`
//   query {
//     desktopLogo: file(relativePath: { eq: "logos/logo.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           width: 900
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Header */
const Header = ({ uri, mediaState }) => {
  const { currentWidth } = mediaState
  const desktopNav = currentWidth !== 'isMobile' && currentWidth !== 'isTablet'

  return (
    <Fragment>
      <header className="container">
        {desktopNav === true && <DesktopNav uri={uri} />}
        {desktopNav === false && <MobileNav uri={uri} />}
      </header>
    </Fragment>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  }),
  (dispatch) => ({})
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedHeader = compose(
  pure, // Wrap it with pure HOC
  withState
)(Header)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedHeader
