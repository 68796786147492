// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import * as Yup from 'yup'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Function
// ----------------------------------------------------------------------------
/** name */
export const name = Yup.string().trim().required('Please enter your name.')

/** email */
export const email = Yup.string()
  .trim()
  .required('Please enter your email.')
  .email('Should be a valid email')

/** comment */
export const comment = Yup.string().trim().nullable().notRequired()

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
// export default name
