// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import pure from 'recompose/pure'

import noop from 'lodash/noop'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { StaticQuery, graphql } from 'gatsby'

import Menu from 'antd/lib/menu'
import 'antd/lib/menu/style/css'

import Dropdown from 'antd/lib/dropdown'
import 'antd/lib/dropdown/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import smallKey from '../../methods/smallKey'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

export const query = graphql`
  query {
    allAuraTalksJson(
      sort: { order: DESC, fields: publishedTimestamp }
      limit: 1
    ) {
      nodes {
        routeSlug
      }
    }
    allAboutJson(sort: { order: ASC, fields: publishedTimestamp }, limit: 1) {
      nodes {
        routeSlug
      }
    }
    allInsightsJson(
      sort: { order: DESC, fields: publishedTimestamp }
      limit: 1
    ) {
      nodes {
        routeSlug
      }
    }
    desktopLogo: file(relativePath: { eq: "logos/fav-icon.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Nav */
const Nav = ({ uri, onClick = noop() }) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Fragment>
        <Link className="home" to="/">
          <GatsbyImage image={getImage(data.desktopLogo)} />
          <h1>
            <span>aura</span>
            <span>network</span>
          </h1>
        </Link>
        <nav>
          <li>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key={smallKey()}>
                    <Link to="/vision">Vision</Link>
                  </Menu.Item>
                  <Menu.Item key={smallKey()}>
                    <Link to={data.allInsightsJson.nodes[0].routeSlug}>
                      Insights
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={smallKey()}>
                    <Link to="https://about.auranetwork.app">Presentation</Link>
                  </Menu.Item>
                  <Menu.Item key={smallKey()}>
                    <Link to="https://book.auranetwork.app/en/">Web Book</Link>
                  </Menu.Item>
                </Menu>
              }
              overlayClassName="part-of-header"
            >
              <Link to="#" onClick={(e) => e.preventDefault()}>
                About
              </Link>
            </Dropdown>
          </li>
          <li>
            <Link to="/team">Team</Link>
          </li>
          <li>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key={smallKey()}>
                    <Link to="/aura-stories">Aura Stories</Link>
                  </Menu.Item>
                  <Menu.Item key={smallKey()}>
                    <Link to={data.allAuraTalksJson.nodes[0].routeSlug}>
                      Aura Talks
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={smallKey()}>
                    <Link to="/community-events">Community Events</Link>
                  </Menu.Item>
                  <Menu.Item key={smallKey()}>
                    <Link to="/blog">Blog</Link>
                  </Menu.Item>
                  <Menu.Item key={smallKey()}>
                    <Link to="/community-feedback">Community Feedback</Link>
                  </Menu.Item>
                </Menu>
              }
              overlayClassName="part-of-header"
            >
              <Link to="#" onClick={(e) => e.preventDefault()}>
                Community
              </Link>
            </Dropdown>
          </li>
          <li>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key={smallKey()}>
                    <Link to="/tutorial/introduction">Tutorial</Link>
                  </Menu.Item>
                  <Menu.Item key={smallKey()}>
                    <Link to="/support">Request support</Link>
                  </Menu.Item>
                  <Menu.Item key={smallKey()}>
                    <Link to="/report-a-bug">Report a bug</Link>
                  </Menu.Item>
                </Menu>
              }
              overlayClassName="part-of-header"
            >
              <Link to="#" onClick={(e) => e.preventDefault()}>
                Help
              </Link>
            </Dropdown>
          </li>
          <li>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key={smallKey()}>
                    <Link to="/apprentice-or-intern">Apprentice or Intern</Link>
                  </Menu.Item>
                  <Menu.Item key={smallKey()}>
                    <Link to="/learn">Learn</Link>
                  </Menu.Item>
                  <Menu.Item key={smallKey()}>
                    <Link to="/donate">Donate</Link>
                  </Menu.Item>
                  <Menu.Item key={smallKey()}>
                    <Link to="/give-feedback">Give Feedback</Link>
                  </Menu.Item>
                </Menu>
              }
              overlayClassName="part-of-header"
            >
              <Link to="#" onClick={(e) => e.preventDefault()}>
                Participate
              </Link>
            </Dropdown>
          </li>
          <li>
            <Link to="/news">Press</Link>
          </li>
          <li>
            <Link to="/faq">FAQs</Link>
          </li>
        </nav>
      </Fragment>
    )}
  />
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedNav = compose(
  pure // Wrap it with pure HOC
)(Nav)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedNav
