// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import compose from 'recompose/compose'
import pure from 'recompose/pure'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { StaticQuery, graphql } from 'gatsby'
import { Spring, animated } from 'react-spring'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

export const query = graphql`
  query {
    allAuraTalksJson(
      sort: { order: DESC, fields: publishedTimestamp }
      limit: 1
    ) {
      nodes {
        routeSlug
      }
    }
    allAboutJson(sort: { order: ASC, fields: publishedTimestamp }, limit: 1) {
      nodes {
        routeSlug
      }
    }
    allInsightsJson(
      sort: { order: DESC, fields: publishedTimestamp }
      limit: 1
    ) {
      nodes {
        routeSlug
      }
    }
    mobileLogo: file(relativePath: { eq: "logos/fav-icon.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** MobileMenu */
class MobileMenu extends React.PureComponent {
  /** [constructor description] */
  constructor() {
    super()

    this.state = {
      isActive: false,
    }

    this.update = this.update.bind(this)
  }

  /** [update description] */
  update(isActive) {
    this.setState({ isActive })
  }

  /** [render description] */
  render() {
    const { isActive } = this.state
    const { uri } = this.props

    return (
      <StaticQuery
        query={query}
        render={(data) => (
          <Fragment>
            <div className="mobile-top">
              <Link className="home" to="/">
                <GatsbyImage image={getImage(data.mobileLogo)} />
                <h1>
                  <span>aura</span>
                  <span>network</span>
                </h1>
              </Link>
              <svg
                style={{
                  width: '2.5rem',
                  height: '2.5rem',
                  cursor: 'help',
                }}
                width="90"
                height="90"
                viewBox="0 0 90 90"
                onClick={() => {
                  this.update(!isActive)
                }}
              >
                <Spring
                  native
                  reset
                  reverse={isActive}
                  from={{ fillO: 1, r: 41 }}
                  to={{ fillO: 0, r: 30 }}
                >
                  {(props) => (
                    <animated.circle
                      stroke="var(--text-color)"
                      strokeWidth={6}
                      fill="var(--text-color)"
                      fillOpacity={props.fillO}
                      cx={45}
                      cy={45}
                      r={props.r}
                    />
                  )}
                </Spring>
              </svg>
            </div>
            <Spring
              native
              reset
              reverse={!isActive}
              from={{ height: '0vh' }}
              to={{
                height: '100vh',
              }}
            >
              {(props) => (
                <animated.div className="mobile-menu-slider" style={props}>
                  <div className="stuff-container">
                    <div className="stuff">
                      <p>
                        <strong>About</strong>
                        <br />
                        <Link to="/vision">Vision</Link>
                        <br />
                        <Link to={data.allInsightsJson.nodes[0].routeSlug}>
                          Insights
                        </Link>
                        <br />
                        <Link to="https://about.auranetwork.app">
                          Presentation
                        </Link>
                        <br />
                        <Link to="https://book.auranetwork.app/en/">
                          Web Book
                        </Link>
                      </p>
                      <p>
                        <strong>Community</strong>
                        <br />
                        <Link to="/aura-stories">Aura Stories</Link>
                        <br />
                        <Link to={data.allAuraTalksJson.nodes[0].routeSlug}>
                          Aura Talks
                        </Link>
                        <br />
                        <Link to="/community-events">Community Events</Link>
                        <br />
                        <Link to="/blog">Blog</Link>
                        <br />
                        <Link to="/community-feedback">Community Feedback</Link>
                      </p>
                      <p>
                        <strong>Help</strong>
                        <br />
                        <Link to="/tutorial/introduction">Tutorial</Link>
                        <br />
                        <Link to="/support">Request support</Link>
                        <br />
                        <Link to="/report-a-bug">Report a bug</Link>
                      </p>
                      <p>
                        <strong>Participate</strong>
                        <br />
                        <Link to="/apprentice-or-intern">
                          Apprentice or Intern
                        </Link>
                        <br />
                        <Link to="/learn">Learn</Link>
                        <br />
                        <Link to="/donate">Donate</Link>
                        <br />
                        <Link to="/give-feedback">Give Feedback</Link>
                      </p>
                      <p>
                        <Link to="/news">Press</Link>
                      </p>
                      <p>
                        <Link to="/faq">FAQs</Link>
                      </p>
                    </div>
                  </div>
                </animated.div>
              )}
            </Spring>
          </Fragment>
        )}
      />
    )
  }
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedMobileMenu = compose(
  pure // Wrap it with pure HOC
)(MobileMenu)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedMobileMenu
