// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import startsWith from 'lodash/startsWith'

import website from '../seo/website.json'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Function
// ----------------------------------------------------------------------------
/** withUrl */
const withUrl = (checkThis) => {
  const { url, nakedUrl } = website
  let returnString = ''

  if (!startsWith(checkThis, 'http')) {
    if (startsWith(checkThis, '/')) {
      returnString = `${nakedUrl}${checkThis}`
    } else {
      returnString = `${url}${checkThis}`
    }
  } else {
    returnString = checkThis
  }

  return returnString
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default withUrl
