// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import map from 'lodash/map'

import classnames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import TextLoop from 'react-text-loop'
import LinesEllipsis from 'react-lines-ellipsis'

import { useMediaQuery } from 'react-responsive'

import 'antd/lib/style/index.css'

import { Textfit } from 'react-textfit'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import MediaState from '../media-state'

import MessageBus from '../message-bus'
import '../message-bus/style.less'

import InitializeMetadata from '../schema/initialize-metadata'
import UpdateTitle from '../schema/update-title'
import OrganisationSchema from '../schema/organisation-schema'
import WebsiteSchema from '../schema/website-schema'

import Link from '../link'
import '../link/style.less'

import Header from '../header'
import '../header/style.less'

import Footer from '../footer'
import '../footer/style.less'

import { updateMediaState, updateScreenSizeState } from '../../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Styles
import '../../styles/index.less'
import '../../styles/type.less'
import '../../styles/container.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

export const exportImageFragments = graphql`
  fragment max90 on File {
    childImageSharp {
      fluid(maxWidth: 90, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment max300 on File {
    childImageSharp {
      fluid(maxWidth: 300, quality: 80, srcSetBreakpoints: [300]) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment max900 on File {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80, srcSetBreakpoints: [300, 600, 900]) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment defaultImage on File {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80, srcSetBreakpoints: [300, 600, 900]) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment max1200 on File {
    childImageSharp {
      fluid(
        maxWidth: 1200
        quality: 80
        srcSetBreakpoints: [300, 600, 900, 1200]
      ) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment max1500 on File {
    childImageSharp {
      fluid(
        maxWidth: 1500
        quality: 80
        srcSetBreakpoints: [300, 600, 900, 1500]
      ) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment max3000 on File {
    childImageSharp {
      fluid(
        maxWidth: 3000
        quality: 80
        srcSetBreakpoints: [300, 600, 900, 1500, 2250, 3000]
      ) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`

export const query = graphql`
  query LayoutQuery {
    logo: file(relativePath: { eq: "logos/logo.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Layout */
class Layout extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      client: false,
    }
  }

  /** after mount */
  componentDidMount() {
    this.setState({ client: true })
  }

  /** on mount */
  componentDidUpdate() {
    if (typeof window !== 'undefined') {
      if (this.state.client === true) {
        const element = document.getElementById('layout')
        element.scrollTop = 0
      }
    }
  }

  /** standard renderer */
  render() {
    const { client } = this.state
    const {
      location,
      children,
      uri = '',
      lightOrDarkMode,
      updateLightOrDarkMode,
      mediaState,
      screenSizeState,
      updateMediaState,
      updateScreenSizeState,
      className = 'standard-page',
    } = this.props

    return (
      <MediaState
        className={classnames('layout', 'container', className)}
        id="layout"
        data-uri={uri}
        useMediaQuery={useMediaQuery}
        updateMediaState={updateMediaState}
        screenSizeState={screenSizeState}
        updateScreenSizeState={updateScreenSizeState}
        useEffect={useEffect}
        useState={useState}
        client={client}
      >
        <InitializeMetadata />
        <UpdateTitle />
        <OrganisationSchema />
        <WebsiteSchema />
        <Header uri={uri} />
        <main role="main">{children}</main>
        <Footer />
      </MediaState>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    lightOrDarkMode: state.lightOrDarkMode,
    mediaState: state.mediaState,
    screenSizeState: state.screenSizeState,
  }),
  (dispatch) => ({
    updateMediaState(payload) {
      dispatch(updateMediaState(payload))
    },
    updateScreenSizeState(payload) {
      dispatch(updateScreenSizeState(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedLayout = compose(
  withState // Add state
)(Layout)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedLayout
