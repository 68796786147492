// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
import Helmet from 'react-helmet'

import website from '../../seo/website.json'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
  * OpenGraphSummary - Implements the Open Graph protocol
  *
  * The Open Graph protocol enables any web page to become a rich object in a social graph.
  * For instance, this is used on Facebook to allow any web page to have the same functionality as any other object on Facebook.
  *
  *
  * @example
    <OpenGraphSummary data={data}>
  *
  * @param {string} data.type - Optional. The type of the webpage. See: https://stackoverflow.com/questions/9275457/facebook-ogtype-meta-tags-should-i-just-make-up-my-own
  * @param {string} data.siteName - Required. The name of the website.
  * @param {string} data.url - Required. The URL of the webpage.
  * @param {string} data.title - Required. The title of the webpage and it's content.
  * @param {string} data.description - Required. A short description of the webpage. Include summary of the webpage.
  * @param {string} data.image - Required. A unique image that will be used as the banner image while sharing on social media.
  *
  * @version 0.0.1
  * @todo none
  * @see {@link http://ogp.me/|Open Graph Docs}
  */
const OpenGraphSummary = ({
  data: { slug, title, description, image } = {
    slug: '/',
    title: 'Page Title',
    description: 'Page Description',
  },
}) => (
  <Helmet
    meta={[
      // ---- Facebook Open Graph
      // { property: 'fb:app_id', content: '123456789' }, // We haven't yet integrated with Facebook Apps.
      {
        property: 'og:type',
        content: 'website',
      },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:site_name', content: website.name },
      {
        property: 'og:url',
        content: `${website.nakedUrlWithIntl}${slug}`,
      },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: image },
    ]}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default OpenGraphSummary
