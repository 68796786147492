// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
import Helmet from 'react-helmet'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import website from '../../seo/website.json'
import about from '../../seo/about.json'
import organisation from '../../seo/organisation.json'
import geoLocation from '../../seo/geo-location.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const dateModified = new Date().toISOString()
const copyrightYearEnds = new Date().getFullYear()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
  * WebpageSchema - Implements schema.org Webpage
  *
  * Describes a webpage
  *
  * @example
    <WebpageSchema data={data}>
  *
  * @param {string} data.url - Required. The url of the page.
  * @param {string} data.name - Required. Name / Title of the page.
  * @param {string} data.description - Required. A short summary of the content on the webpage.
  * @param {string} data.author - Required. An individual who has authored the webpage.
  * @param {string} data.publisher - Required. An organisation that owns the rights to the webpage.
  * @param {string} data.image - Required. A URL of the banner image for the page.
  *
  * @version 0.0.1
  * @todo none
  * @see {@link http://schema.org/WebPage|Schema.org Webpage Docs}
  */
const WebpageSchema = ({
  data: { name, slug, image, abstract, keywords } = {},
}) => {
  const schemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    '@id': `${website.nakedUrlWithIntl}${slug}`,
    url: `${website.nakedUrlWithIntl}${slug}`,
    name,
    abstract,
    image,
    keywords,
    copyrightYear: `${website.copyrightYearBegins}–${copyrightYearEnds}`,
    copyrightNotice: website.copyrightNotice,
    dateCreated: website.created,
    dateModified,
    copyrightHolder: {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: organisation.name,
      legalName: organisation.legalName,
      url: organisation.url,
      logo: organisation.logo,
      foundingDate: organisation.foundingDate,
      founders: organisation.founders.map((founder) => ({
        '@type': 'Person',
        name: founder,
      })),
      address: {
        name: organisation.address.locationName,
        streetAddress: organisation.address.streetAddress,
        addressLocality: organisation.address.addressLocality,
        addressRegion: organisation.address.addressRegion,
        addressCountry: organisation.address.addressCountry,
        postalCode: organisation.address.postalCode,
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: organisation.latitude,
        longitude: organisation.longitude,
      },
      telephone: organisation.telephone,
      email: organisation.email,
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        telephone: organisation.telephone,
        email: organisation.email,
      },
      sameAs: organisation.sameAs,
    },
    contentLocation: {
      '@context': 'https://schema.org',
      '@type': 'Place',
      name: geoLocation.locationName,
      url: geoLocation.locationUrl,
      geo: {
        '@type': 'GeoCoordinates',
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
      },
      address: {
        '@type': 'PostalAddress',
        name: geoLocation.locationName,
        streetAddress: geoLocation.streetAddress,
        addressLocality: geoLocation.addressLocality,
        addressRegion: geoLocation.addressRegion,
        addressCountry: geoLocation.addressCountry,
        postalCode: geoLocation.postalCode,
      },
    },
    locationCreated: {
      '@context': 'https://schema.org',
      '@type': 'Place',
      name: geoLocation.locationName,
      url: geoLocation.locationUrl,
      geo: {
        '@type': 'GeoCoordinates',
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
      },
      address: {
        '@type': 'PostalAddress',
        name: geoLocation.locationName,
        streetAddress: geoLocation.streetAddress,
        addressLocality: geoLocation.addressLocality,
        addressRegion: geoLocation.addressRegion,
        addressCountry: geoLocation.addressCountry,
        postalCode: geoLocation.postalCode,
      },
    },
    author: {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: organisation.name,
      legalName: organisation.legalName,
      url: organisation.url,
      logo: organisation.logo,
      foundingDate: organisation.foundingDate,
      founders: organisation.founders.map((founder) => ({
        '@type': 'Person',
        name: founder,
      })),
      address: {
        name: organisation.address.locationName,
        streetAddress: organisation.address.streetAddress,
        addressLocality: organisation.address.addressLocality,
        addressRegion: organisation.address.addressRegion,
        addressCountry: organisation.address.addressCountry,
        postalCode: organisation.address.postalCode,
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: organisation.latitude,
        longitude: organisation.longitude,
      },
      telephone: organisation.telephone,
      email: organisation.email,
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        telephone: organisation.telephone,
        email: organisation.email,
      },
      sameAs: organisation.sameAs,
    },
    publisher: {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: organisation.name,
      legalName: organisation.legalName,
      url: organisation.url,
      logo: organisation.logo,
      foundingDate: organisation.foundingDate,
      founders: organisation.founders.map((founder) => ({
        '@type': 'Person',
        name: founder,
      })),
      address: {
        name: organisation.address.locationName,
        streetAddress: organisation.address.streetAddress,
        addressLocality: organisation.address.addressLocality,
        addressRegion: organisation.address.addressRegion,
        addressCountry: organisation.address.addressCountry,
        postalCode: organisation.address.postalCode,
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: organisation.latitude,
        longitude: organisation.longitude,
      },
      telephone: organisation.telephone,
      email: organisation.email,
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        telephone: organisation.telephone,
        email: organisation.email,
      },
      sameAs: organisation.sameAs,
    },
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default WebpageSchema
